import { useTranslation } from 'react-i18next';
import './i18n'; // Import the i18n configuration
import { useState, useEffect, ChangeEvent } from 'react';
import axios from 'axios';

function CurrencyToggle() {
  // Step 1: Initialize state for currency
  const [currency, setCurrency] = useState('USD'); // Default to USD
  const { t } = useTranslation();
  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`)
    ? `https://${stagingKey}.peaxel.me`
    : 'https://peaxel.me';

  // Step 2: Retrieve the currency value from localStorage if available
  useEffect(() => {
    const storedCurrency = localStorage.getItem('pxlCurrency');
    if (storedCurrency) {
      setCurrency(storedCurrency);
    }
  }, []); // Empty dependency array to run this effect only once, during component initialization

  // Function to handle currency change
  const handleCurrencyChange = async (event: ChangeEvent<HTMLSelectElement>) => {
    const newCurrency = event.target.value;

    const walletAddress = localStorage.getItem('walletAddress');
    // Update state and localStorage
    setCurrency(newCurrency);
    localStorage.setItem('pxlCurrency', newCurrency);

    if (walletAddress && walletAddress !== "0xf9e2ebb0aa6723e5ff9d7915489fe2188e0b3c8a") {
      try {
        // Construct the API URL using the wallet address and the new currency value
        const apiUrl = `${linkUrl}/wp-json/custom/v1/update_user_metadata/?walletValue=${encodeURIComponent(walletAddress)}&metadataA=peaxel_currency&valueA=${encodeURIComponent(newCurrency)}`;
        
        // Use axios to send the GET request to the API
        const response = await axios.get(apiUrl);
        
        if (response.data.success) {
          console.log('User metadata updated successfully');
        } else {
          console.error('Failed to update user metadata:', response.data.message);
        }
      } catch (error) {
        console.error('Error updating user metadata:', error);
      }
    }

    window.location.reload(); // Reload the page to apply changes
  };

  return (
    <span>
      <select value={currency} onChange={handleCurrencyChange} className="currency-selector">
        <option value="USD">$ {t('dollar')}</option>
        <option value="EUR">€ {t('euro')}</option>
      </select>
    </span>
  );
}

export default CurrencyToggle;
